import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CallZoneUser } from '../../../models/callzone-user';
import { environment } from 'src/environments/environment';
import {
  Appointment,
  AppointmentSearchCriteria,
  ScheduledAppointment,
} from '../../../models/appointment';
import { BeginCallResult } from 'src/app/shared/models/beginCallResult';
import { HandledHttpClient } from '../handledHttpClient';
import { Application } from 'src/app/shared/models/application/application';
import { AppointmentSearchRequest } from 'src/app/shared/models/callzone-appointment';

@Injectable({
  providedIn: 'root',
})
export class CallZoneApiService {
  private baseUrl: string;
  private vcallUrl: string;
  private resourceIds: string;
  private baseUrlForEFullfillment: string;
  constructor(private http: HandledHttpClient, private httpClient: HttpClient) {
    this.baseUrl = environment.config.apis.callzone;
    this.vcallUrl = environment.config.apis.vcall;
    this.resourceIds = environment.resourceIdsList;
    this.baseUrlForEFullfillment = environment.config.apis.rxProfile;
  }

  /**
   * @param userName pass the logged username as a string
   * @description get the user information from the call zone
   * @returns returns the user information
   */
  public GetCallZoneUser(
    userName: string,
    verifyCallZoneUser?: boolean
  ): Observable<CallZoneUser> {
    return this.http.get<CallZoneUser>(
      this.baseUrl + '/Resource/' + encodeURIComponent(userName),
      {
        errorMessage: verifyCallZoneUser
          ? 'Unable to get Resource from callzone for ' + userName
          : null,
      }
    );

    // return new Observable((o) => {
    //   const user = new CallZoneUser();
    //   user.email = 'Bradd.Pitt@usg.com';
    //   user.name = 'Brad Pitt';
    //   user.resourceId = 55555;
    //   o.next(user);
    // });
  }
  /**
   * @param criteria pass the criteria as an object with startDate endDate and with resourceId
   * @description get the appointment lists from the cal zone
   * @returns returns the list of appointments
   */
  public GetAppointments(criteria: AppointmentSearchCriteria) {
    const resourceIds = JSON.parse(this.resourceIds);
    const matchedResourceId = resourceIds.filter((r) => {
      return r === Number(criteria.resourceId);
    });
    if (
      matchedResourceId.length > 0 &&
      criteria.canAccessFakeAppointment === 'true'
    ) {
      return this.getDummyAppointment();
    } else {
      if (criteria.endDate && criteria.startDate) {
        return this.http.get<Appointment[]>(
          this.baseUrl + '/Appointment/' + criteria.resourceId.toString(),
          {
            params: {
              startDate: criteria.startDate,
              endDate: criteria.endDate,
            },
          }
        );
      } else {
        return this.http.get<Appointment[]>(
          this.baseUrl + '/Appointment/' + criteria.resourceId
        );
      }
    }
  }
  /**
   * @param criteria pass the appointment data as an object client, dateTime, product, policy,  and appId
   * @description start the call for verification
   * @returns call started or not
   */
  public BeginCall(appointment: Appointment) {
    return this.http.post<BeginCallResult>(
      this.baseUrl + `/Appointment/${appointment.appointmentId}/StartCall`,
      {
        resourceId: appointment.resourceId,
      },
      {
        errorMessage: 'Callzone Could not start the call',
        successMessage: 'CallZone notified that the call has begun',
      }
    );

    // return new Observable((o) => {
    //   const result = new BeginCallResult();
    //   result.alreadyStarted = true;
    //   result.beganCallOn = new Date();
    //   result.message = 'Nothing';
    //   o.next(result);
    // });
  }

  /*
   * @description for complete the call
   * @returns It returns call completed or not confirmation
   */
  public EndCallAsCompleted(
    application: Application,
    appointment: Appointment,
    completedCallType: number,
    recallGuid: string
  ) {
    let recallObject = {};
    if (recallGuid) {
      recallObject = {
        ApplicationId: appointment.appId,
        completeCallType: completedCallType,
        RecallId: recallGuid,
        emailAddress: application.contact.email,
      };
    } else {
      recallObject = {
        appointmentId: appointment.appointmentId,
        ApplicationId: appointment.appId,
        ResourceId: appointment.resourceId,
        MarkApplicationCompleteType: completedCallType,
        PolicyNumber: application.policyInformation[0].controlNumber,
        emailAddress: application.contact.email,
      };
    }
    return this.http.post<BeginCallResult>(
      this.baseUrl + `/Appointment/${appointment.appointmentId}/CompleteCall`,
      recallObject,
      {
        errorMessage: 'Unexpected error occured',
        successMessage: 'CallZone notified to end the call.',
      }
    );
  }
  /**
   * @description get the call reasons from the call zone
   * @returns stop call reason information
   */
  public GetStopCallReasons() {
    return this.http.get<BeginCallResult>(
      this.baseUrl + '/Appointment/EndCallreasons',
      {
        errorMessage: 'Unexpected error occured',
      }
    );
  }

  /**
   * @param appId pass the appId as a parameter
   * @description create the appointment in the call zone for verification
   */
  public CreateAppointment<T>(appId: any): Observable<any> {
    return this.http.post<T>(
      this.baseUrl + '/Appointment/createrecallappointment',
      appId,
      {
        errorMessage: `Error in retrieving appointment id`,
      }
    );
  }

  /**
   * @param appId pass the appId as a parameter
   * @description create the appointment in the call zone for verification
   */
  public GetApplicationHashCode(appId: any): Observable<any> {
    const headers = new HttpHeaders({
      'x-permissions':
        'MembershipRecallRead, MembershipRecallReadWrite, MembershipVCallRead, MembershipVCallReadWrite,MembershipAddOnRead,MembershipAdmin,MembershipAddOnReadWrite',
    });
    return this.httpClient.request(
      'GET',
      this.baseUrl + `/Application/${appId}/hashcode`,
      { responseType: 'text', headers: headers }
    );
  }

  /**
   * @description gte the all the recall reasons from the call zone
   * @returns call reason information
   */
  public GetRecallReasons<T>(): Observable<T> {
    return this.http.get<T>(this.baseUrl + '/Appointment/RecallReasons', {
      errorMessage: `Error retrieving recall reasons`,
    });
  }

  /**
   * @param search the search object with applicationId?: number,  applicantFirstName?: string, applicantLastName?: string, state?: string, pageNumber?: number, pageSize?: number;
   * @description search the appointments from the call Zone
   * @returns appointment lists
   */
  public searchAppointments<T>(
    search: AppointmentSearchRequest
  ): Observable<T[]> {
    return this.http.post<T[]>(
      this.baseUrl + '/Appointment/CallZoneSearch',
      search,
      {
        errorMessage: `Error search appointments'`,
      }
    );
  }
  public getDummyAppointment() {
    return new Observable<Appointment[]>((o) => {
      const list = new Array<Appointment>();
      const appt = new Appointment({
        appointmentId: 3372,
        appId: 4004721,
        scheduledDate: new Date(),
        firstName: 'AUSTIN',
        lastName: 'TROY',
        username: 'HQ\\jyothik',
        callType: 'Standard',
        message: 'Successful',
        statusId: 0,
        statusName: 'TODO',
      });
      list.push(appt);
      o.next(list);
    });
  }

  public noShowCall(statusCode: CallZoneStopCallRequest): Observable<any> {
    return this.http.post<BeginCallResult>(
      this.baseUrl + `/Appointment/${statusCode.appointmentId}/NoShow`,
      statusCode,
      {
        errorMessage: `Error saving reason for application = '${statusCode.appointmentId}'`,
        successMessage: 'Reason for no show saved!',
        responseDecider: 'SaveStatusCodeAndReason',
      }
    );
  }

  public stopCall(statusCode: CallZoneStopCallRequest): Observable<any> {
    return this.http.post<BeginCallResult>(
      this.baseUrl + `/Appointment/${statusCode.appointmentId}/StopCall`,
      statusCode,
      {
        errorMessage: `Error saving reason for application = '${statusCode.appointmentId}'`,
        successMessage: 'Reason for stop call saved!',
        responseDecider: 'SaveStatusCodeAndReason',
      }
    );
  }

  public onCall(appointment: Appointment) {
    return this.http.post<BeginCallResult>(
      this.baseUrl + `/Appointment/${appointment.appointmentId}/OnCall`,
      {
        resourceId: appointment.resourceId,
      },
      {
        errorMessage: 'CallZone returned an error',
        successMessage: 'CallZone notified that the call has on call',
      }
    );
  }

  public GetCallTypes() {
    return this.http.get<CallType[]>(this.baseUrl + '/Appointment/CallTypes', {
      errorMessage: 'Unexpected error occured',
    });
  }

  public GetStatusTypes() {
    return this.http.get<StatusType[]>(
      this.baseUrl + '/Appointment/StatusTypes',
      {
        errorMessage: 'Unexpected error occured',
      }
    );
  }

  public GetEFullfillmentDetails(applicationId: string, relationship: string) {
    return this.http.get<boolean>(
      `${this.baseUrlForEFullfillment}/IsAuthorizedAsync/${applicationId}/${relationship}`
    );
  }

  public GetScheduledAppointment(appointmentId: number) {
    return this.http.get<ScheduledAppointment>(
      `${this.baseUrl}/Appointment/${appointmentId}/Detail`);
  }
}

export interface CallZoneStopCallRequest {
  statusId: number;
  stopCallId: number;
  appointmentId: number;
  resourceId: number;
  notes?: string;
  leftVoiceMailMessage?: boolean;
  smsParameters?: SmsParameters;
}

export class CallType {
  label: string;
  order: string;
  is_Active: boolean;
}

export class StatusType {
  label: string;
  order: string;
  is_Active: boolean;
}
export class SmsParameters {
  applicationId: number;
  messageId: string;
  message: string;
  appointmentId: number;
  applicantPhoneNumber: string;
  controlNumber: string;
  featureType: string;
  agentNumber: string;
}
