import { config } from 'src/app/developer/smoke-test/config/config.production';
export const environment = {
  features: {
    sms: false,
    UW_AdditionalInfo: true,
    developerNavigation: false,
    clarity: false,
    useSelfService: true,
  },
  production: true,
  clarity: 'e4g185p1hq',
  config: {
    apis: {
      callzone: 'https://vcall-gateway-prod.ushealthgroup.com/api/callzone/v1',
      vcall: 'https://vcall-gateway-prod.ushealthgroup.com/api/vcall/v1',
      usps: 'https://vcall-gateway-prod.ushealthgroup.com/api/usps/v1',
      prescription:
        'https://vcall-gateway-prod.ushealthgroup.com/api/medicalsearch/v1/medicationlookup',
      ezapp: 'https://vcall-gateway-prod.ushealthgroup.com/api/ezapp/v1/',
      appt: 'https://vcall-gateway-prod.ushealthgroup.com/api/callzone/v1',
      logger: 'https://vcall-gateway-prod.ushealthgroup.com/api',
      rxProfile:
        'https://vcall-gateway-prod.ushealthgroup.com/api/rxprofile/v1',
      efulfillment:
        'https://vcall-gateway-prod.ushealthgroup.com/api/efulfillment/v1',
      search: 'https://vcall-gateway-prod.ushealthgroup.com/api/',
      providerLookup:
        'https://vcall-gateway-prod.ushealthgroup.com/api/medicalsearch/v2/providerlookup',
      secureEmail:
        'https://nbc-gateway-prod.ushealthgroup.com/billing/v1/sendemail', // not yet create in prod as of 3/29
    },
    environment: 'prod',
    adRoles: `PRD_VCall2_HQSysAdmin, PRD_VCall2_ReadOnly, PRD_VCall2_HQSupervisor, PRD_VCall2_CCSupervisor, PRD_VCall2_HQCCVerifiers, PRD_VCall2_HQUnderwriter, PRD_VCall2_HQSupervisor_Vendors, PRD_VCall2_HQCCVerifiers_Vendors, PRD_VCall2_CCSupervisor_Vendors, USHG_APS2_Supervisor_PRD, USHG_APS2_SysAdmin_PRD, USHG_APS2_TeamMember_PRD, USHG_APS2_MatchupMember_PRD, USHG_APS2_FieldSupport_PRD`,
    callZoneStatusConfiguration: {
      voicemailModelNoShow: {
        statusId: 3,
        stopcallId: 0,
      },
      voicemailModelWrongNumber: {
        statusId: 4,
      },
      stopCall: {
        statusId: 4,
      },
      stopCallReasonForReschedule: {
        stopCallId: 16,
        stopCallLabel: 'Applicant Asked Verifier To Reschedule The Call',
      },
    },
  },
  baseApplicationDocumentUrl:
    'https://vcall-gateway-prod.ushealthgroup.com/api/ezapp/v1',
  graphqluri: 'https://nbc-gateway-prod.ushealthgroup.com/api/graphql',
  apsApiUrl: 'https://nbc-gateway-prod.ushealthgroup.com',
  authConfig: {
    tenant: 'cc7c826f-6444-4c0e-8314-83afb87c98ea',
    clientId: '9cc56910-a79f-4af2-af27-1e840bc91d8f',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: 'https://vcall2.ushealthgroup.com',
    addedProtectedResourceMap: [
      {
        key: 'https://vcall-gateway-prod.ushealthgroup.com/api',
        list: ['api://9ef44e1d-7a93-4946-92c9-5ad3656921c8/users.execute'],
      },
      {
        key: 'https://nbc-gateway-prod.ushealthgroup.com',
        list: ['api://9ef44e1d-7a93-4946-92c9-5ad3656921c8/users.execute'],
      }
    ],
    cacheLocation: 'localStorage',
  },
  instrumentationKey: 'cf3a7d3c-87f0-4375-96cf-85a25b045b21',
  listOfApisAndServicesForSmokeTest: config.listOfServicesWithApis,
  resourceIdsList: '[5247,5245,5247,5246,5253,5256,5215]',

  callzoneAppointmentUrl: 'https://cz.ushealthgroup.com',

  rescheduleAppointmentUrl:
    'https://cz.ushealthgroup.com/Appointment_CS/SchedulingiFrame.aspx',
  selfServiceUrl: 'https://apss.ushealthgroup.com',
  userguideConfig:
    'https://stdiagmi01scus01prod.blob.core.windows.net/vc2-training/data.json?sp=r&st=2022-02-03T17:05:40Z&se=2032-02-04T01:05:40Z&spr=https&sv=2020-08-04&sr=b&sig=4%2FdRGKaCxpCnrr3iLZ4BtiL0ptv6%2FAVenL7jWRn33rw%3D',

  releaseNotesConfigUrl:
    'https://stdiagmi01scus01prod.blob.core.windows.net/vc2-training/release.json?sp=r&st=2022-02-03T16:00:19Z&se=2032-02-04T00:00:19Z&spr=https&sv=2020-08-04&sr=b&sig=yMLndjsKqjm5Fnnak1BZNjRXBeG3fI%2FBdnuc1%2F8oPmY%3D',

  businessReleaseNotesConfigUrl:
    'https://stdiagmi01scus01prod.blob.core.windows.net/vc2-training/business-release.json?sp=r&st=2022-02-03T15:59:19Z&se=2032-02-03T23:59:19Z&spr=https&sv=2020-08-04&sr=b&sig=9jK8lJm2ZjpGW5oexrX1Iuw2rytuoLp8qrMMDw61H9k%3D',
  viewmode: {
    review: ['review', 'vCall', 'ezApp', 'recalls'],
    aps: ['review', 'vCall', 'ezApp', 'recalls', 'apsrequests'],
    addOns: ['review', 'vCall', 'ezApp', 'recalls', 'addOns'],
    default: ['review', 'vCall', 'ezApp', 'recalls'],
  },
  productAppTypes: [12, 13, 17, 25],

  allowedBrowsers: {
    browsers: ['Google Chrome', 'Microsoft Edge'],
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
